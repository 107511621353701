header {
  height: 100vh;
  padding-top: 3rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* ================= CTA =================== */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
  align-items: center;
}

/* ================= HeaderSocials =================== */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* ============= ME ================= */
.me {
  background: var(--color-primary);
  width: 22rem;
  height: 30rem;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  margin-top: 4rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
}

/* ==================SCroll down =========== */

.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/* =============== Media queries(Medium devices)============ */
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}

/* =============== Media queries(Small devices)============ */
@media screen and (max-width: 600px) {
  .conatiner {
    padding-top: 4rem;
  }
  header {
    height: 70vh;
  }
  .me {
    width: 16rem;
    height: 22rem;
  }
  .scroll__down {
  }
  .header__socials {
    bottom: 1rem;
  }
}
